<ng-template #confirmationModal
    let-c="close">
  <app-modal-confirmation [title]="contentText()"
      (closeModal)="close()">
    <ng-template appCustomModalBody>
      <div class="modal-body f-18"
          [ngClass]="actionType ==='approve' ? 'p-3' : 'py-4'">
        <div class="text-center"
            *ngIf="!enableReason">
          <span>{{"APPROVAL.CONTENT-NO-REQUIRED"|translate}}</span>
        </div>
        <div *ngIf="enableReason">
          <span>{{ reasonLabel | translate }}</span>
          <div class="form-group mt-2">
            <textarea class="form-control"
                fz-elastic
                [(ngModel)]="reason"
                (ngModelChange)="onReasonChange()">
            </textarea>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template appCustomModalFooter>
      <div class="modal-footer">
        <button class="btn btn-outline"
            (click)="canSkipReason ? skip() : close()"
            [style.--c]="themeList?.header_table_color">
          {{ (canSkipReason ? 'MEMOS.SKIP' : 'MEMOS.CANCEL') | translate }}
        </button>
        <button class="btn btn-confirm"
            type="button"
            appSetTheme
            [disabled]="enableReason && !reason"
            (click)="submit()">
          {{ 'MEMOS.CONFIRM' | translate }}
        </button>
      </div>
    </ng-template>
  </app-modal-confirmation>
</ng-template>
